.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(3px, 0, 0);
  }

  30%, 50%, 30% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 40% {
    transform: translate3d(8px, 0, 0);
  }
}

.my-node-enter {
  opacity: 0;
  transform: translateX(70%)
}
.my-node-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 500ms;
}
.my-node-exit {
  opacity: 0;
  transform: translateX(70%);
}
.my-node-exit-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 500ms;
}

.splashScreen{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}


.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #000000 transparent #000000 transparent;
  animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.hidden-button {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.clickable-text {
  cursor: pointer;
  text-decoration: underline;
}