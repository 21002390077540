.menu-wrapper-custom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid  var(--bs-app-sidebar-light-separator-color)  
}

/* .menu-wrapper-custom.visible {
} */



@media (min-width: 960px) {
  .menu-wrapper-custom {
    position: static;
    border-top: none;
  }
}